<template>
  <el-row >
    <el-col :offset="3" :span="25" >

      <div>
      <el-menu
          :default-active="path"
          class="el-menu-demo"
          mode="horizontal"
          router
      >

        <el-menu-item index="/front/home" style="font-size: 12px;margin-left: -20px">首页</el-menu-item>
        <el-menu-item index="/front/introduction"style="font-size: 12px">学校简介</el-menu-item>
        <el-menu-item index="/front/seek"style="font-size: 12px">报名咨询</el-menu-item>
        <el-menu-item index="/front/song"style="font-size: 12px">学校风采</el-menu-item>
        <el-menu-item index="/front/person"style="font-size: 12px;margin-left: -20px">个人信息</el-menu-item>

        <div style="height: auto;" >
          <div style="margin-top: auto" v-if="user.username">
        <el-dropdown style="margin-top: auto">
        <span class="el-dropdown-link">
          用户：{{ user.username}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <template #dropdown>
            <el-dropdown-menu>
<!--              <el-dropdown-item @click="$router.push('/person')">个人信息</el-dropdown-item>-->
              <el-dropdown-item @click="$router.push('/login')">退出系统</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
          </div>
          <el-button style="margin-top:10px;margin-left: 0px" v-else @click="$router.push('/login')">登录</el-button>
        </div>
      </el-menu>
      </div>
      <router-view />
    </el-col>
  </el-row>
</template>
<script>

import request from "@/utils/request";

export default {
  name: "Front",
  data() {
    return {
      path: this.$route.path,   // 设置默认高亮的菜单
      user: {}
    }
  },
  created() {
    this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
  },
  methods: {
    sign() {
      request.post("/user/sign/" + this.user.id).then(res => {
        this.$message.success('签到成功，积分+10')
        setTimeout(() => {
          location.reload()
        }, 1000)
      })
    }
  }
}

</script>

<style scoped>
.el-dropdown-link{
height: 5px;
}

</style>